import http from '../http-common'
import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'

export class Competitions extends APIBaseFunctions {
  static CompetitionsDataService = class {
    private static cachedCompetitionsDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    /* static processDataSliceObj (dataSliceObj: APIDataSliceObj | null = null) : APIDataSliceObj {
      let currentDataSliceObj: APIDataSliceObj

      if (dataSliceObj != null) {
        // Update cached data object values if requested
        if ((dataSliceObj.slicemode & 128) > 0) {
          currentDataSliceObj = dataSliceObj
          currentDataSliceObj.slicemode = dataSliceObj.slicemode - 128
          this.cachedCompetitionsDataSliceObj = currentDataSliceObj
        } else {
          // Do not update cached data object values, but still do use the given data object values for the current API access
          currentDataSliceObj = dataSliceObj
        }
      } else {
        // Reuse cached data object values
        currentDataSliceObj = this.cachedCompetitionsDataSliceObj
      }

      switch (currentDataSliceObj.slicemode) {
        case 3:
          // Go to the last page based upon the pagesize data and update start and limit data accordingly
          Competitions.dataSliceGotoEndPage(currentDataSliceObj)
          break
        case 2:
          // Go to the first page based upon the pagesize data and update start and limit data accordingly
          Competitions.dataSliceGotoBeginPage(currentDataSliceObj)
          break
        case 1:
          // Use page and pagesize data to update start and limit data accordingly
          Competitions.dataSliceGotoPage(currentDataSliceObj)
          break
        case 0:
        default:
          // Nothing to do
      }

      return currentDataSliceObj
    } */

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      // const currentDataSliceObj = this.processDataSliceObj(dataSliceObj)
      // console.log('[getAll] 1 - cachedCompetitionsDataSliceObj = ' + JSON.stringify(this.cachedCompetitionsDataSliceObj))
      const currentDataSliceObj = Competitions.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedCompetitionsDataSliceObj }, dataSliceObj)
      // console.log('[getAll] 2 - cachedCompetitionsDataSliceObj = ' + JSON.stringify(this.cachedCompetitionsDataSliceObj))
      // console.log('[getAll] 3 - currentDataSliceObj = ' + JSON.stringify(currentDataSliceObj))

      if (sortingOrder.length > 0) {
        return http.get(`/staevners?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/staevners?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/staevners/${id}`)
    }

    static getCount (extraCountParameter = '') {
      return http.get('/staevners/count' + (extraCountParameter.length > 0 ? `?${extraCountParameter}` : ''))
    }

    static create (data: any) {
      return http.post('/staevners', data)
    }

    static update (id: string, data: any) {
      return http.put(`/staevners/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/staevners/${id}`)
    }

    static deleteAll () {
      return http.delete('/staevners')
    }

    static findByCompetitionStatus (status: boolean, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let sortingOrderParameter = ''
      const currentDataSliceObj = Competitions.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedCompetitionsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      return http.get(`/staevners?staevne_status=${status}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static findBySearchTerm (status: boolean, ignoreStatus: boolean, searchTerm: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let retVal
      let sortingOrderParameter = ''
      const currentDataSliceObj = Competitions.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedCompetitionsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      if (ignoreStatus) {
        retVal = http.get(`/staevners?_where[_or][0][staevne_navn_contains]=${searchTerm}&_where[_or][1][staevne_spillested_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      } else {
        retVal = http.get(`/staevners?staevne_status=${status}&_where[_or][0][staevne_navn_contains]=${searchTerm}&_where[_or][1][staevne_spillested_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return retVal
    }

    static findByNameMatch (status: boolean, ignoreStatus: boolean, searchTerm: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let retVal
      let sortingOrderParameter = ''
      const currentDataSliceObj = Competitions.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedCompetitionsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      if (ignoreStatus) {
        retVal = http.get(`/staevners?_where[_or][0][staevne_navn]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      } else {
        retVal = http.get(`/staevners?staevne_status=${status}&_where[_or][0][staevne_navn]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return retVal
    }
  }

  static CompetitionRowsDataService = class {
    private static cachedCompetitionRowsDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Competitions.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedCompetitionRowsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/staevne-raekkers?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/staevne-raekkers?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/staevne-raekkers/${id}`)
    }

    static getCount () {
      return http.get('/staevne-raekkers/count')
    }

    static create (data: any) {
      return http.post('/staevne-raekkers', data)
    }

    static update (id: string, data: any) {
      return http.put(`/staevne-raekkers/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/staevne-raekkers/${id}`)
    }

    static deleteAll () {
      return http.delete('/staevne-raekkers')
    }

    static findByCompetitionRowStatus (status: boolean, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let sortingOrderParameter = ''
      const currentDataSliceObj = Competitions.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedCompetitionRowsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      return http.get(`/staevne-raekkers?staevne_raekker_status=${status}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static findBySearchTerm (status: boolean, ignoreStatus: boolean, searchTerm: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let retVal
      let sortingOrderParameter = ''
      const currentDataSliceObj = Competitions.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedCompetitionRowsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      if (!isNaN(Number(searchTerm))) {
        // Handle price amount in search term
        if (ignoreStatus) {
          retVal = http.get(`/staevne-raekkers?staevne_raekker_tilmeldingsgebyr=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        } else {
          retVal = http.get(`/staevne-raekkers?staevne_raekker_status=${status}&staevne_raekker_tilmeldingsgebyr=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        }
      } else {
        if (ignoreStatus) {
          retVal = http.get(`/staevne-raekkers?_where[_or][0][staevner_id.staevne_navn_contains]=${searchTerm}&_where[_or][1][staevner_id.staevne_spillested_contains]=${searchTerm}&_where[_or][2][staevne_raekker_navn_contains]=${searchTerm}&_where[_or][3][staevner_id.staevne_kategori_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        } else {
          retVal = http.get(`/staevne-raekkers?staevne_raekker_status=${status}&_where[_or][0][staevner_id.staevne_navn_contains]=${searchTerm}&_where[_or][1][staevner_id.staevne_spillested_contains]=${searchTerm}&_where[_or][2][staevne_raekker_navn_contains]=${searchTerm}&_where[_or][3][staevner_id.staevne_kategori_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        }
      }

      return retVal
    }
  }

  static CompetitionMemberRegistrationDataService = class {
    private static cachedCompetitionRegistrationsDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Competitions.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedCompetitionRegistrationsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/staevne-tilmeldings?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/staevne-tilmeldings?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string, extraParameter = '') {
      return http.get(`/staevne-tilmeldings/${id}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static getCount () {
      return http.get('/staevne-tilmeldings/count')
    }

    static create (data: any) {
      return http.post('/staevne-tilmeldings', data)
    }

    static update (id: string, data: any) {
      return http.put(`/staevne-tilmeldings/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/staevne-tilmeldings/${id}`)
    }

    static deleteAll () {
      return http.delete('/staevne-tilmeldings')
    }

    static findByCompetitionRegistrationStatus (status: boolean, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let sortingOrderParameter = ''
      const currentDataSliceObj = Competitions.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedCompetitionRegistrationsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      return http.get(`/staevne-tilmeldings?staevne_tilmelding_status=${status}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }
  }

  static CompetitionForeignResultsDataService = class {
    private static cachedCompetitionForeignResultsDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Competitions.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedCompetitionForeignResultsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/ud-resultats?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/ud-resultats?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/ud-resultats/${id}`)
    }

    static getCount (extraCountParameter = '') {
      return http.get('/ud-resultats/count' + (extraCountParameter.length > 0 ? `&${extraCountParameter}` : ''))
    }

    static create (data: any) {
      return http.post('ud-resultats', data)
    }

    static update (id: string, data: any) {
      return http.put(`ud-resultats/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/ud-resultats/${id}`)
    }

    static deleteAll () {
      return http.delete('/ud-resultats')
    }

    static findBySearchTerm (searchTerm: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let sortingOrderParameter = ''
      const currentDataSliceObj = Competitions.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedCompetitionForeignResultsDataSliceObj }, dataSliceObj)
      searchTerm = searchTerm.trim()

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      return http.get(`/ud-resultats?_where[_or][0][staevne_raekker_id.staevne_raekker_navn_contains]=${searchTerm}&_where[_or][1][ud_staevner_id.ud_staevne_navn_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }
  }

  static ForeignCompetitionDataService = class {
    private static cachedForeignCompetitionDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Competitions.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedForeignCompetitionDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/ud-staevners?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/ud-staevners?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/ud-staevners/${id}`)
    }

    static getCount (extraCountParameter = '') {
      return http.get('/ud-staevners/count' + (extraCountParameter.length > 0 ? `&${extraCountParameter}` : ''))
    }

    static create (data: any) {
      return http.post('/ud-staevners', data)
    }

    static update (id: string, data: any) {
      return http.put(`/ud-staevners/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/ud-staevners/${id}`)
    }

    static deleteAll () {
      return http.delete('/ud-staevners')
    }

    static findBySearchTerm (searchTerm: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let sortingOrderParameter = ''
      const currentDataSliceObj = Competitions.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedForeignCompetitionDataSliceObj }, dataSliceObj)
      searchTerm = searchTerm.trim()

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      return http.get(`/ud-staevners?_where[_or][0][ud_staevne_navn_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }
  }

  static CompetitionResultsDataService = class {
    private static cachedCompetitionResultsDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Competitions.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedCompetitionResultsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/staevne-resultaters?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/staevne-resultaters?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string, extraParameter = '') {
      return http.get(`/staevne-resultaters/${id}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static getCount (extraCountParameter = '') {
      return http.get('/staevne-resultaters/count' + (extraCountParameter.length > 0 ? `?${extraCountParameter}` : ''))
    }

    static create (data: any) {
      return http.post('/staevne-resultaters', data)
    }

    static update (id: string, data: any) {
      return http.put(`/staevne-resultaters/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/staevne-resultaters/${id}`)
    }

    static deleteAll () {
      return http.delete('/staevne-resultaters')
    }

    static findByCompetitionResultStatus (status: boolean, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let sortingOrderParameter = ''
      const currentDataSliceObj = Competitions.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedCompetitionResultsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      return http.get(`/staevne-resultaters?staevne_resultater_status=${status}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static findByCompetitionRowAndMember (status: boolean, ignoreStatus: boolean, searchDateTerm: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, competitionId: number, competitionRowId: number, competitionMemberId: number, extraParameter = '') {
      let retVal
      let sortingOrderParameter = ''
      const currentDataSliceObj = Competitions.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedCompetitionResultsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      if (ignoreStatus) {
        retVal = http.get(`/staevne-resultaters?_where[0][staevne_resultater_dato]=${searchDateTerm}&_where[1][medlem_id.id]=${competitionMemberId}&_where[2][staevner_id.id]=${competitionId}&_where[3][staevne_raekker_id.id]=${competitionRowId}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      } else {
        retVal = http.get(`/staevne-resultaters?staevne_resultater_status=${status}&_where[0][staevne_resultater_dato]=${searchDateTerm}&_where[1][medlem_id.id]=${competitionMemberId}&_where[2][staevner_id.id]=${competitionId}&_where[3][staevne_raekker_id.id]=${competitionRowId}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return retVal
    }

    static findByCompetitionRowAndPlayerAltName (status: boolean, ignoreStatus: boolean, searchDateTerm: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, competitionId: number, competitionRowId: number, competitionPlayerAltName: string, extraParameter = '') {
      let retVal
      let sortingOrderParameter = ''
      const currentDataSliceObj = Competitions.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedCompetitionResultsDataSliceObj }, dataSliceObj)
      competitionPlayerAltName = competitionPlayerAltName.trim().toLocaleLowerCase()

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      if (ignoreStatus) {
        retVal = http.get(`/staevne-resultaters?_where[0][staevne_resultater_dato]=${searchDateTerm}&_where[1][staevne_resultater_navn]=${competitionPlayerAltName}&_where[2][staevner_id.id]=${competitionId}&_where[3][staevne_raekker_id.id]=${competitionRowId}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      } else {
        retVal = http.get(`/staevne-resultaters?staevne_resultater_status=${status}&_where[0][staevne_resultater_dato]=${searchDateTerm}&_where[1][staevne_resultater_navn]=${competitionPlayerAltName}&_where[2][staevner_id.id]=${competitionId}&_where[3][staevne_raekker_id.id]=${competitionRowId}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return retVal
    }
  }

  static OldCompetitionResultsDataService = class {
    private static cachedOldCompetitionResultsDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Competitions.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedOldCompetitionResultsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/gamle-ranglistes?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }
      return http.get(`/gamle-ranglistes?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }
  }

  static CompetitionRankingsPointsDataService = class {
    private static cachedCompetitionRankingsPointsDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Competitions.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedCompetitionRankingsPointsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/staevne-ranglistepoints?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/staevne-ranglistepoints?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string, extraParameter = '') {
      return http.get(`/staevne-ranglistepoints/${id}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static getCount () {
      return http.get('/staevne-ranglistepoints/count')
    }

    static create (data: any) {
      return http.post('/staevne-ranglistepoints', data)
    }

    static update (id: string, data: any) {
      return http.put(`/staevne-ranglistepoints/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/staevne-ranglistepoints/${id}`)
    }

    static deleteAll () {
      return http.delete('/staevne-ranglistepoints')
    }

    static findByCompetitionRankingsPointStatus (status: boolean, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let sortingOrderParameter = ''
      const currentDataSliceObj = Competitions.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedCompetitionRankingsPointsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      return http.get(`/staevne-ranglistepoints?staevne_ranglistepoint_status=${status}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static findBySearchTerm (status: boolean, ignoreStatus: boolean, searchTerm: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let retVal
      let sortingOrderParameter = ''
      const currentDataSliceObj = Competitions.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedCompetitionRankingsPointsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      if (!isNaN(Number(searchTerm))) {
        // Handle price amount in search term
        if (ignoreStatus) {
          retVal = http.get(`/staevne-ranglistepoints?staevne_ranglistepoint_point=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        } else {
          retVal = http.get(`/staevne-ranglistepoints?staevne_ranglistepoint_status=${status}&staevne_ranglistepoint_point=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        }
      } else {
        if (ignoreStatus) {
          retVal = http.get(`/staevne-ranglistepoints?_where[_or][0][staevne_ranglistepoint_kategori_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        } else {
          retVal = http.get(`/staevne-ranglistepoints?staevne_ranglistepoint_status=${status}&_where[_or][0][staevne_ranglistepoint_kategori_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
        }
      }

      return retVal
    }
  }
}

export default new Competitions()
